.about section {
  text-align: center;
  margin: 50px 0 0 0;
}
.about img {
  width: 350px;
  margin: 40px 0;
}

.about h1 {
  font-size: 50px;
  margin-left: 20px;
}

.about h2,
h3,
li {
  font-size: 22px;
  line-height: 1.5;
  margin-left: 20px;
}

.about .header-text {
  margin: 70px 0 100px 0;
}

@media (max-width: 992px) {
  .about h1 {
    font-size: 35px;
  }

  .about h2 {
    margin-top: 0;
    text-align: center;
  }

  .about h3,
  li {
    font-size: 18px;
  }

  .about h3 {
    text-align: center;
  }

  .about img {
    display: block;
    margin: 0 auto;
    width: 50%;
    margin-top: 30px;
  }

  .about .header-text {
    margin: 30px 0 100px 0;
  }

  .about .listing {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .about .buttons {
    text-align: center;
  }
}
