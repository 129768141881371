.services section {
  text-align: center;
}

.services h2 {
  text-align: left;
  margin-bottom: 20px;
}
.services h4 {
  font-size: 20px;
}

.services a {
  color: #060b26;
  text-decoration: none;
}

.services a:hover {
  opacity: 0.7;
}

.services .building-icon {
  font-size: 50px;
  margin-bottom: 23px;
}
.services .couch-icon {
  font-size: 50px;
  margin-bottom: 20px;
}

.services .compass-icon {
  font-size: 45px;
  margin-bottom: 25px;
}

.services .bulb-icon {
  font-size: 53px;
  margin-bottom: 18px;
}

@media (max-width: 992px) {
  .services h4 {
    font-size: 15px;
    word-break: break-word;
  }
}
@media (max-width: 768px) {
  .services h4 {
    font-size: 12px;
    word-break: break-word;
  }
}

@media (max-width: 576px) {
  .services h4 {
    font-size: 10px;
    word-break: break-word;
  }
}
