.contact section {
  text-align: center;
  margin: 50px 0;
}

.contact label {
  font-size: 20px;
  margin: 20px 0 10px 0;
}

.contact form {
  margin: 25px 85px 75px 100px;
}

@media (max-width: 992px) {
  .contact h1 {
    font-size: 35px;
  }
  .contact form {
    margin: 0;
  }
}
