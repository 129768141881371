.footer {
  background: #060b26;
  height: 90px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.footer img {
  width: 200px;
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
}

.footer small {
  color: white;
}

.footer .icons {
  color: #fff;
  font-size: 25px;
  margin: 30px 10px 0 10px;
}

@media (min-width: 992px) {
}
