@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f4f4f4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}



.App {
  min-height: 100vh;
  position: relative;
  padding-bottom: 90px;
}

.btn-branding {
  color: white;
  background: #a88b70;
  margin-top: 20px;
  margin-left: 20px;
  padding: 10px 20px;
  border: 1px solid #a88b70;
  border-radius: 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.btn-branding:hover {
  opacity: 0.9;
}

.about h1,
.works h1,
.prices h1,
.contact h1 {
  font-size: 50px;
  font-weight: bold;
}

.navbar {
  background: #060b26;
  height: 80px;
}

.navbar img {
  width: 200px;
  position: absolute;
  bottom: 10px;
  right: 120px;
}

.navbar select {
  position: absolute;
  bottom: 25px;
  right: 40px;
}

.navbar .navbar-icons {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}
.nav-menu {
  background-color: #060b26;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}

@media (max-width: 400px) {
  .navbar img {
    width: 47%;
    margin-bottom: 3px;
  }

  .navbar .menu-bars {
    margin: 5px;
  }
}

.home {
  text-align: center;
  height: 100%;
}

.home .container {
  padding: 0 50px;
  max-width: 100%;
}

.home h1 {
  font-size: 50px;
  padding: 50px 0;
}

.home h4 {
  font-size: 20px;
}

.home .work-button {
  color: #060b26;
  text-decoration: none;
}

.home a:hover {
  opacity: 0.7;
}

.home img {
  border-radius: 10px;
  margin-bottom: 20px;
  width: 357px;
  height: 238px;
}

.home .couch-icon {
  font-size: 50px;
  display: block;
  margin: 0 auto;
}

.home .compass-icon {
  font-size: 45px;
  display: block;
  margin: 0 auto;
}

.home .bulb-icon {
  font-size: 53px;
  display: block;
  margin: 0 auto;
}

.home .building-icon {
  font-size: 45px;
  display: block;
  margin: 0 auto;
}

.home section {
  margin: 90px 0;
}

@media (max-width: 992px) {
  .home h1 {
    font-size: 35px;
  }

  .maintitle {
    margin-top: 10px;
  }
  .undertitle {
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .home .couch-icon {
    margin-bottom: 20px;
  }

  .home .compass-icon {
    margin-bottom: 25px;
  }

  .home .bulb-icon {
    margin-bottom: 18px;
  }

  .home .building-icon {
    margin-bottom: 23px;
  }
}

.works section {
  text-align: center;
  margin: 50px 0;
}

.works h2 {
  text-align: left;
  margin-bottom: 20px;
}
.works h4 {
  font-size: 20px;
}

.works i {
  font-size: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.works a {
  color: #060b26;
  text-decoration: none;
}

.works i:hover {
  opacity: 0.7;
}

@media (max-width: 768px) {
  .works h1 {
    font-size: 35px;
  }

  .works h2 {
    font-size: 14px;
    font-weight: bold;
  }
}

.services section {
  text-align: center;
}

.services h2 {
  text-align: left;
  margin-bottom: 20px;
}
.services h4 {
  font-size: 20px;
}

.services a {
  color: #060b26;
  text-decoration: none;
}

.services a:hover {
  opacity: 0.7;
}

.services .building-icon {
  font-size: 50px;
  margin-bottom: 23px;
}
.services .couch-icon {
  font-size: 50px;
  margin-bottom: 20px;
}

.services .compass-icon {
  font-size: 45px;
  margin-bottom: 25px;
}

.services .bulb-icon {
  font-size: 53px;
  margin-bottom: 18px;
}

@media (max-width: 992px) {
  .services h4 {
    font-size: 15px;
    word-break: break-word;
  }
}
@media (max-width: 768px) {
  .services h4 {
    font-size: 12px;
    word-break: break-word;
  }
}

@media (max-width: 576px) {
  .services h4 {
    font-size: 10px;
    word-break: break-word;
  }
}

.about section {
  text-align: center;
  margin: 50px 0 0 0;
}
.about img {
  width: 350px;
  margin: 40px 0;
}

.about h1 {
  font-size: 50px;
  margin-left: 20px;
}

.about h2,
h3,
li {
  font-size: 22px;
  line-height: 1.5;
  margin-left: 20px;
}

.about .header-text {
  margin: 70px 0 100px 0;
}

@media (max-width: 992px) {
  .about h1 {
    font-size: 35px;
  }

  .about h2 {
    margin-top: 0;
    text-align: center;
  }

  .about h3,
  li {
    font-size: 18px;
  }

  .about h3 {
    text-align: center;
  }

  .about img {
    display: block;
    margin: 0 auto;
    width: 50%;
    margin-top: 30px;
  }

  .about .header-text {
    margin: 30px 0 100px 0;
  }

  .about .listing {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .about .buttons {
    text-align: center;
  }
}

.prices section {
  text-align: center;
  margin: 50px 0;
}

.prices .column {
  text-align: center;
  margin-bottom: 100px;
}

.prices .orderbutton {
  margin: 0;
}

@media (max-width: 768px) {
  .prices h1 {
    font-size: 35px;
  }
  .prices p {
    font-size: 12px;
  }

  .prices .orderbutton {
    font-size: 12px;
    padding: 8px 12px;
  }
}

@media (max-width: 576px) {
  .prices h2 {
    font-size: 15px;
    margin-top: 0px;
  }
  .prices p {
    font-size: 10px;
  }

  .prices .orderbutton {
    font-size: 10px;
    padding: 8px 12px;
  }
}

.contact section {
  text-align: center;
  margin: 50px 0;
}

.contact label {
  font-size: 20px;
  margin: 20px 0 10px 0;
}

.contact form {
  margin: 25px 85px 75px 100px;
}

@media (max-width: 992px) {
  .contact h1 {
    font-size: 35px;
  }
  .contact form {
    margin: 0;
  }
}

h2 {
  margin: 50px 0 20px 0;
}

.listing {
  margin-bottom: 100px;
}

.footer {
  background: #060b26;
  height: 90px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.footer img {
  width: 200px;
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
}

.footer small {
  color: white;
}

.footer .icons {
  color: #fff;
  font-size: 25px;
  margin: 30px 10px 0 10px;
}

@media (min-width: 992px) {
}

