@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;400&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}



.App {
  min-height: 100vh;
  position: relative;
  padding-bottom: 90px;
}

.btn-branding {
  color: white;
  background: #a88b70;
  margin-top: 20px;
  margin-left: 20px;
  padding: 10px 20px;
  border: 1px solid #a88b70;
  border-radius: 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.btn-branding:hover {
  opacity: 0.9;
}

.about h1,
.works h1,
.prices h1,
.contact h1 {
  font-size: 50px;
  font-weight: bold;
}
