.prices section {
  text-align: center;
  margin: 50px 0;
}

.prices .column {
  text-align: center;
  margin-bottom: 100px;
}

.prices .orderbutton {
  margin: 0;
}

@media (max-width: 768px) {
  .prices h1 {
    font-size: 35px;
  }
  .prices p {
    font-size: 12px;
  }

  .prices .orderbutton {
    font-size: 12px;
    padding: 8px 12px;
  }
}

@media (max-width: 576px) {
  .prices h2 {
    font-size: 15px;
    margin-top: 0px;
  }
  .prices p {
    font-size: 10px;
  }

  .prices .orderbutton {
    font-size: 10px;
    padding: 8px 12px;
  }
}
