.navbar {
  background: #060b26;
  height: 80px;
}

.navbar img {
  width: 200px;
  position: absolute;
  bottom: 10px;
  right: 120px;
}

.navbar select {
  position: absolute;
  bottom: 25px;
  right: 40px;
}

.navbar .navbar-icons {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}
.nav-menu {
  background-color: #060b26;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}

@media (max-width: 400px) {
  .navbar img {
    width: 47%;
    margin-bottom: 3px;
  }

  .navbar .menu-bars {
    margin: 5px;
  }
}
